import React from "react";
import {Link, navigate} from "@reach/router";
import {Button, Checkbox, Form, Input, TextArea} from "formik-semantic-ui";
import {Icon} from "semantic-ui-react";
import FileUpload from "../test1/FileUpload";
import styles from "./friendform.module.css";

function encode(data) {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
        formData.append(key, data[key]);
    }

    return formData;
}

class FriendForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vorname: '',
            nachname: '',
            botschaft: '',
            datenschutz: false,
        };
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleAttachment = e => {
        // console.log(e.target.files[0]);
        this.setState({ [e.target.name]: e.target.files[0] });
    };

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/?no-cache=1", {
            method: "POST",
            // headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
            .then(() => navigate(form.getAttribute("action")))
            .catch(error => console.log(error));
    };

    _handleSubmit2 = (values, formikApi) => {
        let errors = false;
        setTimeout(() => {
                Object.keys(values).forEach(key => {
                    if (!values.firstNameVal) {
                        errors = true;
                        formikApi.setFieldError("firstNameVal", "Pflichtfeld");
                    }
                    if (!values.lastNameVal) {
                        errors = true;
                        formikApi.setFieldError("lastNameVal", "Pflichtfeld");
                    }
                    if (!values.privacyVal) {
                        errors = true;
                        formikApi.setFieldError("privacyVal", "Pflichtfeld");
                    }
                    if (!values.messageVal) {
                        errors = true;
                        formikApi.setFieldError("messageVal", "Pflichtfeld");
                    }
                    if (!!!values.fileVal) {
                        errors = true;
                        formikApi.setFieldError("fileVal", "Bild fehlt!");
                    }
                    if (!!values.fileVal && values.fileVal.size > 3000000) {
                        errors = true;
                        formikApi.setFieldError("fileVal", "Datei ist zu groß! Bitte laden Sie eine Bildatei unter 3 MB hoch.");
                    }

                    if (errors) {
                        formikApi.setSubmitting(false);
                    }
                });
                if (!errors) {
                    this.setState({vorname: values.firstNameVal});
                    this.setState({nachname: values.lastNameVal});
                    this.setState({botschaft: values.messageVal});
                    this.setState({datenschutz: values.privacyVal});
                    this.setState({bild: values.fileVal});
                    formikApi.setSubmitting(true);
                    document.getElementById('friend-submit').click();
                }

            },
            150);

    };

    render() {
        const {vorname, nachname, botschaft, datenschutz, bild} = this.state;
        return (
            <div className={styles.main}>
                <form
                    name="Freund werden"
                    method="post"
                    action="/freunde/danke/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                    hidden
                >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="freundWerden" />
                    <p hidden>
                        <label>
                            Don’t fill this out:{" "}
                            <input name="bot-field" onChange={this.handleChange} />
                        </label>
                    </p>
                    <p>
                        <label>
                            Your name:<br />
                            <input type="text" name="vorname" onChange={this.handleChange} value={vorname} />
                        </label>
                    </p>
                    <p>
                        <label>
                            Your name:<br />
                            <input type="text" name="nachname" onChange={this.handleChange} value={nachname} />
                        </label>
                    </p>
                    <p>
                        <label>
                            Message:<br />
                            <textarea name="botschaft" onChange={this.handleChange} value={botschaft} />
                        </label>
                    </p>
                    <p>
                        <label>
                            Privacy:<br />
                            <input type="text" name="datenschutz" onChange={this.handleChange} value={datenschutz}/>
                        </label>
                    </p>
                    <p>
                        <label>
                            File:<br />
                            <input id='file-input' name="bild" type="file" onChange={this.handleAttachment} />
                        </label>
                    </p>
                    <p>
                        <button type="submit" id={'friend-submit'}>Send</button>
                    </p>
                </form>

                <Form
                    initialValues={{
                        firstNameVal: '',
                        lastNameVal: '',
                        messageVal: '',
                        privacyVal: false,
                    }}
                    onSubmit={this._handleSubmit2}
                    render={({setFieldValue }) => (

                        <Form.Children>
                            <Form.Group widths="2">
                                <Input label="Vorname*" name="firstNameVal"/>
                                <Input label="Nachname*" name="lastNameVal"/>

                            </Form.Group>

                            <TextArea label="Nachricht*" name="messageVal"/>
                            <FileUpload label="Bild*" name="fileVal" />
                            {/*<label>Bild*</label>*/}
                            {/*<input id="file" name="fileVal" type="file" onChange={(event) => {*/}
                                {/*console.log(event.currentTarget.value);*/}
                                {/*setFieldValue("fileVal", event.currentTarget.value);*/}
                                {/*this.setState({file: event.currentTarget.value});*/}
                            {/*}} className="form-control" />*/}
                            <label className={styles.labelPrivacy}><Link to={'/datenschutz/'}><Icon name={'privacy'}/>Datenschutzhinweise</Link></label>
                            <Checkbox label="zur Kenntnis genommen & akzeptiert*" name="privacyVal">
                            </Checkbox>

                            <Button.Submit className={styles.submitButton}>Absenden</Button.Submit>
                        </Form.Children>
                    )}
                />

            </div>

        )
    }

}
export default FriendForm;