import React from "react";
import '../../styles/semantic.min.css';
import SEO from "../../components/seo/Seo";
import LayoutContainer from "../../container/LayoutContainer";
import PageHeader from "../../components/utils/PageHeader";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import FriendForm from "../../components/supporters/FriendForm";
import {Container} from "semantic-ui-react";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import layout from "../../templates/layout.module.css";

export default () => <LayoutContainer>
    <SEO title={'Freund werden'}/>
    <MobileTabletContainer>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Jetzt Freund werden'} subHeader={'Foto und Botschaft hochladen'}/>
            <Container>
                <FriendForm/>
            </Container>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Jetzt Freund werden'} subHeader={'Foto und Botschaft hochladen'}/>
            <Container>
                <FriendForm/>
            </Container>
        </Container>
        <Footer/>
        </div>
    </DesktopContainer>


</LayoutContainer>