import React, {Component} from "react";
import {Form, Header, Icon, Progress, Segment} from "semantic-ui-react";
import {Field} from "formik";
import Dropzone from "react-dropzone";
import classNames from 'classnames'

const validFileStyle = {
    borderColor: "blue"
};

const invalidFileStyle = {
    borderColor: "red"
};

const emptyStyle = {};

let fieldCounter = 0;
class FileUpload extends Component {
    state = {
        uploading: false,
        progress: 0,
    };

    constructor(props) {
        super(props);
        this.id = props.id || `field_fileupload_${fieldCounter++}`;
    }

    _onDrop = (form, inputProps, acceptedFiles) => {
        const { name } = this.props;
        this.setState({ uploading: true, progress: 0 }, () => {
            this._counter = setInterval(() => {
                this.setState(
                    state => ({
                        progress: state.progress + 10
                    }),
                    () => {
                        if (this.state.progress >= 100) {
                            clearInterval(this._counter);
                            form.setFieldValue(name, [...acceptedFiles].pop(), true);
                            // console.log([...acceptedFiles].pop());
                            this.setState({ uploading: false });
                        }
                    }
                );
            }, 150);
        });
    };

    render() {
        const { name, label, fieldProps = {}, inputProps = {}} = this.props;
        return (
            <Field
                name={name}
                render={({ field, form }) => {
                    const error = form.touched[name] && form.errors[name];
                    return (
                        <Form.Field error={!!error} {...fieldProps}>
                            {!!label && <label htmlFor={this.id}>{label}</label>}
                            {/*<Dropzone onDrop={this.onDrop}>*/}
                                {/*{({getRootProps, getInputProps, isDragActive}) => {*/}
                                    {/*return (*/}
                                        {/*<div*/}
                                            {/*{...getRootProps()}*/}
                                            {/*className={classNames('dropzone', {'dropzone--isActive': isDragActive})}*/}
                                        {/*>*/}
                                            {/*<input {...getInputProps()} />*/}
                                            {/*{*/}
                                                {/*isDragActive ?*/}
                                                    {/*<p>Drop files here...</p> :*/}
                                                    {/*<p>Try dropping some files here, or click to select files to upload.</p>*/}
                                            {/*}*/}
                                        {/*</div>*/}
                                    {/*)*/}
                                {/*}}*/}
                            {/*</Dropzone>*/}
                            <Dropzone
                                accept="image/*"
                                style={emptyStyle}
                                multiple={false}
                                onDrop={acceptedFiles => {
                                    if (acceptedFiles.length) {
                                        this._onDrop(form, inputProps, acceptedFiles);
                                    }
                                }}
                            >
                                {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => {
                                    const wrapperStyle = {
                                        border: "1px dashed rgba(34,36,38,.15)",
                                        boxShadow: "none",
                                        cursor: "copy",
                                        ...(isDragActive ? validFileStyle : emptyStyle),
                                        ...(isDragReject || error ? invalidFileStyle : emptyStyle)
                                    };
                                    const { uploading, progress} = this.state;
                                    const file = [...acceptedFiles].pop();
                                    // console.log(file);
                                    const hasFile = !!file && !uploading && field.value;
                                    const color = hasFile
                                        ? "green"
                                        : isDragActive || uploading
                                            ? "blue"
                                            : "grey";

                                    const showProgress = !!progress && (hasFile || uploading);

                                    return (
                                        <div
                                            {...getRootProps()}
                                            className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                                        >

                                        <Segment style={wrapperStyle}>
                                            {showProgress && (
                                                <Progress
                                                    percent={progress}
                                                    attached="top"
                                                    autoSuccess
                                                    color="blue"
                                                />
                                            )}
                                            <Header
                                                icon
                                                textAlign="center"
                                                size="small"
                                                color={color}
                                            >
                                                <Icon name="cloud upload" color={color} />
                                                <Header.Content>
                                                    {file && (hasFile || uploading) ? (
                                                        <React.Fragment>
                                                            {file.name} - {file.size} Bytes
                                                            <Header.Subheader>
                                                                {uploading ? "Lädt hoch.." : "Hochgeladen"}.
                                                            </Header.Subheader>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            Bild hier platzieren
                                                            <Header.Subheader>
                                                                Oder per Klick auswählen
                                                            </Header.Subheader>
                                                        </React.Fragment>
                                                    )}
                                                </Header.Content>
                                            </Header>
                                            <input {...getInputProps()} />
                                        </Segment>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                            {form.errors[name] &&
                            form.touched[name] && (
                                <span
                                    style={{
                                        display: "block",
                                        margin: ".28571429rem 0",
                                        color: "red",
                                        fontStyle: 'italic'
                                    }}
                                >
                    {form.errors[name]}
                  </span>
                            )}
                        </Form.Field>
                    );
                }}
            />
        );
    }
}

export default FileUpload;
